<template>
  <div
    v-tap="analysisData('1-7-1-19')"
    v-expose="analysisData('1-7-1-18')"
    :data-ready="String(isReady)"
    class="cart-item-store"
    :class="{'pointer': showJump}"
    @click="goToStore"
  >
    <template v-if="item.preferred_seller_store == 1">
      <PreferredSellerStoreIcon
        :width="cssConfig.preferredSellerWidth"
        :height="cssConfig.preferredSellerHeight"
        :public-cdn="publicCdn"
      />
    </template>
    <template v-else>
      <sui_icon_store_16px
        v-if="isNewStoreIcon"
        :size="cssConfig.storeIconFontSize + 'px'"
      />
      <svg
        v-else
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{
          width: cssConfig.storeIconFontSize + 'px',
          height: cssConfig.storeIconFontSize + 'px',
        }"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.2457 2.24023L14.6168 8.24764H13.3189V13.7557H2.67822V8.24764H1.37988L2.75097 2.24023H13.2457ZM9.22335 3.24023H6.77319V7.24759H9.22335V3.24023ZM10.2234 7.24759L13.3628 7.24764L12.4482 3.24023H10.2234V7.24759ZM3.67822 8.24764V12.7557H12.3189V8.24764H3.67822ZM5.77319 7.24759V3.24023H3.54845L2.63383 7.24764L5.77319 7.24759Z"
          fill="currentColor"
        />
      </svg>
    </template>
    <span 
      class="title"
      :style="{
        'font-size': cssConfig.textFontSize + 'px',
        'font-weight': cssConfig.fontWeight
      }"
    >
      {{ storeTitle }}
    </span>
    <img
      v-if="!!trendIconUrl"
      class="trend-icon"
      :src="trendIconUrl"
    />
    <sui_icon_more_right_12px_1
      v-if="showJump"
      size="12px"
      :is-rotate="GBCssRight"
    />
  </div>
</template>

<script>
import PreferredSellerStoreIcon from 'public/src/pages/cart_new/components/preferredSellerStoreIcon.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { sui_icon_more_right_12px_1, sui_icon_store_16px } from '@shein-aidc/icon-vue3'
import { mapGetters } from 'vuex'
daEventCenter.addSubscriber({ modulecode: '1-7-1' })

export default {
  name: 'CartItemStore',
  components: {
    PreferredSellerStoreIcon,
    sui_icon_more_right_12px_1,
    sui_icon_store_16px,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    showOBMStore: {
      type: Boolean,
      default: true,
    },
    showThreeStore: {
      type: Boolean,
      default: true,
    },
    storeSelectsInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    cssConfig: {
      type: Object,
      default() {
        return {}
      }
    },
    locals: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['storeTrendLogoItem']),
    isNewStoreIcon() {
      return this.item.isNewStoreIcon
    },
    publicCdn() {
      return this.locals?.PUBLIC_CDN || gbCommonInfo.PUBLIC_CDN || ''
    },
    siteName () {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW ? 'ROMWE' : 'SHEIN'
    },
    storeTitle() {
      if (this.showOBMStore) {
        return this.item.store_title
      }

      return this.showThreeStore && this.item.business_model == 1 ? this.item.store_title : this.siteName
    },
    showJump() {
      return this.item.store_code != 1 && this.storeTitle != this.siteName
    },
    GBCssRight() {
      return this.locals?.GB_cssRight || gbCommonInfo.GB_cssRight
    },
    trendIconUrl() {
      return this.storeTrendLogoItem[this.item?.store_code] || false
    }
  },
  watch: {
    storeTrendLogoItem: {
      handler() {
        this.isReady = true
      },
      deep: true,
    }
  },
  methods: {
    analysisData(id) {
      return {
        id,
        waitReady: true,
        data: {
          store_code: this.item.store_code,
          store_tp: this.item.store_type == 1 ? 1 : 2,
          preferred_seller: this.item.preferred_seller_store == 1 ? 1 : 0,
          store_label: this.trendIconUrl ? 'trends' : '-',
        }
      }
    },
    goToStore() {
      if (!this.showJump || !this.storeSelectsInfo[this.item.store_code]) return
      window.open(this.storeSelectsInfo[this.item.store_code], '_blank' )
    }
  },
}
</script>

<style lang="less" scoped>
.cart-item-store {
  display: inline-flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  &.pointer {
    cursor: pointer;
  }
  
  .title {
    margin: 0 2px;
    color: #000;
    overflow : hidden;
    text-overflow: ellipsis;
  }

  .sui_icon_more_right_12px_1 {
    transform: rotateZ(0deg)/* rtl: rotateZ(180deg) */;
  }
  .trend-icon {
    width: 44px;
    height: 14px;
  }
}
</style>
